<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
  >
    <g
      id="Сгруппировать_192"
      data-name="Сгруппировать 192"
      transform="translate(-310 -2524)"
    >
      <rect
        id="Прямоугольник_246"
        data-name="Прямоугольник 246"
        width="31"
        height="31"
        rx="6"
        transform="translate(310 2524)"
        fill="#9a9a9a"
      />
      <path
        id="Контур_314"
        data-name="Контур 314"
        d="M17.966,3.622s1.665-.594,1.526.848c-.045.593-.463,2.671-.786,4.919L17.6,16.046s-.093.975-.926,1.144a3.9,3.9,0,0,1-2.313-.763c-.184-.127-3.469-2.035-4.625-2.969-.323-.254-.694-.763.046-1.357l4.857-4.24c.555-.509,1.445-1.442-.868,0l-6.81,3.774a2.947,2.947,0,0,1-2.128.042L1.823,10.83s-1.11-.636.786-1.272C7.235,7.564,12.924,5.53,17.966,3.621Z"
        transform="translate(314.5 2529.5)"
        fill="#393939"
      />
    </g>
  </svg>
</template>
