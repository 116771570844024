<script>
export default {
  props: {
    links: {
      type: Array,
      required: true
    }
  }
}
</script>

<template>
  <nav class="hidden xl:block relative container py-5 border-t border-gray">
    <ul
      class="flex flex-row flex-nowrap"
      :class="[links.length > 4 ? 'justify-between' : 'space-x-28']"
    >
      <li v-for="link in links" :key="link">
        <router-link :to="link.href">
          {{ link.title }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>
