<script>
export default {
  name: 'RNavLinks',
  props: {
    links: {
      type: Array,
      required: true
    }
  }
}
</script>

<template>
  <ul
    class="h-full flex flex-row content-end text-right justify-end transition-opacity duration-300"
  >
    <li v-for="link in links" :key="link.title" class="pl-12 h-5 my-auto">
      <router-link :to="link.href">
        <span class="text-black text-lg font-medium">
          {{ link.title }}
        </span>
      </router-link>
    </li>
  </ul>
</template>
