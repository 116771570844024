<script setup lang="ts">
import { useWindowSize } from "@vueuse/core";
import { useUserStore } from "../stores";
// @ts-expect-error no type import static assets
import Rusdram42 from "../assets/rusdram-43.svg";

const mobileHeaderHeight = 53;

const { height, width } = useWindowSize();

const mediaNavLink = [
  {
    title: "Фото альбомы",
    href: "/media/photos",
  },
  {
    title: "Видео",
    href: "/media/videos",
  },
  {
    title: "Пресса",
    href: "/media/press",
  },
];

const theaterNavLink = [
  {
    title: "Наша команда",
    href: "/theater/staff",
  },
  {
    title: "Труппа",
    href: "/theater/troupe",
  },
  {
    title: "Спектакли",
    href: "/theater/performs",
  },
  {
    title: "Зрительные залы",
    href: "/theater/halls",
  },
  {
    title: "Голосования",
    href: "/theater/voutings",
  },
];

const links = ref([
  {
    title: "Афиша",
    href: "/posters",
    children: [],
  },
  {
    title: "Театр",
    href: "/theater",
    children: theaterNavLink,
  },
  {
    title: "Новости",
    href: "/news",
    children: [],
  },
  // {
  //   title: 'Правила посещения',
  //   href: '/rules'
  // },
  {
    title: "Документы",
    href: "/documents",
    children: [],
  },
  // {
  //   title: 'Абонементы',
  //   href: '#'
  // },
  {
    title: "Медиа",
    href: "/media",
    children: mediaNavLink,
  },
  {
    title: "Контакты",
    href: "/contacts",
    children: [],
  },
]);

const isMenuOpen = ref(false)

const route = useRoute();

const userStore = useUserStore();

const user = computed(() => userStore.user)

const links1 = computed(() => {
  return links.value.slice(0, parseInt((links.value.length / 2).toString()));
});
const links2 = computed(() => {
  return links.value.slice(
    parseInt((links.value.length / 2).toString()),
    links.value.length
  );
});

function onMenuClick() {
  if (!isMenuOpen.value) {
    isMenuOpen.value = true;
  } else {
    isMenuOpen.value = false;
  }
}

function onNavClick() {
  if (width.value < 1280) isMenuOpen.value = false;
}

const navHeight = computed(() => {
  return height.value - mobileHeaderHeight;
});

const currentSecondNav = computed(() => {
  return links.value.find((link) => {
    if (route.fullPath.includes(link.href)) return link.children;
  });
});

function checkChildrenRoutesToRender(path) {
  return links.value.find((link) => {
    if (route.fullPath.includes(path)) return link.children;
  });
}
</script>

<template>
  <div>
    <header class="relative pt-4 pb-5 xl:pt-9 xl:pb-10">
      <div class="container relative xl:h-15 grid grid-cols-12 px-4 xl:px-0">
        <div class="h-full col-start-1 col-end-10 xl:col-end-4">
          <nuxt-link to="/">
            <img loading="lazy" class="h-full w-80" :src="Rusdram42" alt />
          </nuxt-link>
        </div>
        <nav
          class="h-full flex flex-row content-end justify-end col-start-10 xl:col-start-4 col-end-13"
        >
          <div class="my-auto relative w-full h-full hidden xl:block">
            <r-nav-list
              class="absolute top-0 left-0 right-0 bottom-0"
              :class="{
                'opacity-0': isMenuOpen,
                'opacity-100': !isMenuOpen,
                'z-10': !isMenuOpen,
              }"
              :links="links1"
            ></r-nav-list>
            <r-nav-list
              class="absolute top-0 left-0 right-0 bottom-0"
              :class="{
                'opacity-100': isMenuOpen,
                'opacity-0': !isMenuOpen,
                'z-10': isMenuOpen,
              }"
              :links="links2"
            ></r-nav-list>
          </div>
          <div class="my-auto relative flex flex-row">
            <nuxt-link v-if="!user" to="/login" class="hidden xl:block">
              <r-button
                defaultColor="dark-gray"
                textColor="white"
                class="h-10 px-12 ml-12"
                >Вход</r-button
              >
            </nuxt-link>
            <nuxt-link v-else to="/user" class="hidden xl:block">
              <r-button
                class="h-10 px-12 ml-12"
                defaultColor="dark-gray"
                textColor="white"
                >{{ user.firstName ? user.firstName : user.userName }}</r-button
              >
            </nuxt-link>
            <button
              class="h-10 w-8 ml-12 border-none focus:outline-none flex flex-col justify-between menu-button"
              :class="isMenuOpen ? 'is-open' : ''"
              @click="onMenuClick"
            >
              <hr
                class="w-full border-0 bg-dark-gray rounded-sm transform transition-all ease-linear duration-300"
              />
              <hr
                class="w-full border-0 bg-dark-gray rounded-sm transform transition-all ease-linear duration-300"
              />
              <hr
                class="w-full border-0 bg-dark-gray rounded-sm transform transition-all ease-linear duration-300"
              />
            </button>
          </div>
        </nav>
        <nav
          class="col-start-1 col-end-13 transition-all duration-700 overflow-hidden text-center flex xl:hidden flex-col justify-center space-y-10"
          :style="`height: ${isMenuOpen ? navHeight + 'px' : '0px'}`"
        >
          <ul class="flex flex-col space-y-5 justify-center">
            <li class="h-5 my-auto">
              <nuxt-link to="/" @click="onNavClick">
                <span class="text-black text-lg2 font-semibold">Главная</span>
              </nuxt-link>
            </li>
            <li v-for="link in links" :key="link.title" class="my-auto">
              <nuxt-link :to="link.href" @click="onNavClick">
                <span class="text-black text-lg2 font-semibold leading-none">{{
                  link.title
                }}</span>
              </nuxt-link>
              <ul
                v-if="checkChildrenRoutesToRender(link.href)"
                class="flex flex-col space-y-2 justify-center mt-2"
              >
                <li
                  v-for="child in link.children"
                  :key="child.title"
                  class="h-5 my-auto"
                >
                  <nuxt-link :to="child.href" @click="onNavClick">
                    <span class="text-gray-700 text-base font-semibold">{{
                      child.title
                    }}</span>
                  </nuxt-link>
                </li>
              </ul>
            </li>
          </ul>
          <div class="mx-auto w-1/2 pt-10 pb-10 border-gray-700 border-t">
            <nuxt-link v-if="!user" to="/login" @click="isMenuOpen = false">
              <r-button
                defaultColor="dark-gray"
                textColor="white"
                class="h-10 px-12"
                >Вход</r-button
              >
            </nuxt-link>
            <nuxt-link v-else to="/user" @click="isMenuOpen = false">
              <r-button
                class="h-10 px-12"
                defaultColor="dark-gray"
                textColor="white"
                >{{ user.firstName ? user.firstName : user.userName }}</r-button
              >
            </nuxt-link>
          </div>
        </nav>
      </div>
    </header>
    <r-second-nav
      v-if="currentSecondNav && currentSecondNav.children.length"
      :links="currentSecondNav.children"
    ></r-second-nav>
  </div>
</template>

<style scoped lang="scss">
.menu-button {
  position: relative;
  hr {
    position: absolute;
    height: 2px;
    min-height: 2px;
    max-height: 2px;
    opacity: 1;
  }
  hr:nth-child(1) {
    top: 6px;
  }
  hr:nth-child(2) {
    top: calc(50% - 1px);
  }
  hr:nth-child(3) {
    bottom: 6px;
  }
}
.menu-button.is-open {
  hr:nth-child(1) {
    transform: rotate(45deg) !important;
    top: 19px;
  }
  hr:nth-child(2) {
    opacity: 0;
  }
  hr:nth-child(3) {
    transform: rotate(-45deg) !important;
    bottom: 19px;
  }
}
</style>
